:root {
    --mainColor: rgb(172, 27, 27);
    --hoverColor: rgb(202, 76, 76);
    --backgroundColor: #1e1e1e;
    --darkOne: #f3f3f3;
    --darkTwo: #fff;
    --lightOne: #ccc;
    --lightTwo: #e7e3e3;
    --mainHex: #ac1b1b;
    --primaryContent: #f7cfcf;
    --primaryDark: #6a1f1f;
    --darkGrey: #4d4d4e;
    --complementaryColor: #FFD700;
}

.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }

        li.submit-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 50px;
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: var(--backgroundColor);
        height: 50px;
        color: var(--lightOne);
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
    }

    textarea {
        width: 100%;
        border: 0;
        background: var(--backgroundColor);
        height: 50px;
        font-size: 16px;
        color: var(--lightOne);
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: var(--complementaryColor);
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid var(--complementaryColor);
        border-radius: 4px;
        float: right;
        transition: .3s ease-in-out;

        &:hover {
            cursor: pointer;
            background: var(--backgroundColor);
            color: var(--complementaryColor);
            border: 1px solid var(--backgroundColor);
        }
    }

    .spinner {
        width: 30px;
        height: 30px;
        border: 3px solid var(--complementaryColor);
        border-top: 3px solid var(--backgroundColor);
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .message {
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        font-weight: bold;

        &.success {
            color: var(--complementaryColor);
        }

        &.error {
            color: var(--complementaryColor);
        }
    }

    ::placeholder {
        color: var(--lightOne);
        opacity: 1;
    }
      
    :-ms-input-placeholder {
        color: var(--lightOne);
    }
      
    ::-ms-input-placeholder {
        color: var(--lightOne);
    }
}

@media screen and (max-width: 1200px) {
    .tags,
    .contact-page h1:before,
    .contact-page h1:after {
        display: none;
    }
    
    .contact-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        h1 {
            font-size: 30px;
        }
    }
}