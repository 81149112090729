:root {
    --mainColor: rgb(172, 27, 27);
    --hoverColor: rgb(202, 76, 76);
    --backgroundColor: #1e1e1e;
    --darkOne: #f3f3f3;
    --darkTwo: #fff;
    --lightOne: #ccc;
    --lightTwo: #e7e3e3;
    --mainHex: #ac1b1b;
    --primaryContent: #f7cfcf;
    --primaryDark: #6a1f1f;
    --darkGrey: #4d4d4e;
}

.nav-bar {
    background: #181818;
    width: 60px;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 45px;
            height: auto;
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ffd700;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &:after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.projects-link {
            &:after {
                content: 'PROJECTS';
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;
                color: #4d4d4e;
            }

            &:hover {
                .anchor-icon {
                    color: green;
                }
            }
        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}


@media screen and (max-width: 1200px) {
    .nav-bar {
      background: transparent;
      position: initial;
      height: auto;
      min-height: auto;
  
      ul, nav {
        display: none;
      }
  
      nav {
        width: 100%;
        top: 0;
        left: 0;
        background: #181818;
        height: 100%;
        margin: 0;
        z-index: 2;
        position: fixed;
  
        &.mobile-show {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
  
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        a:after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10px;
        }
  
        a svg {
          opacity: 1 !important;
        }
      }
  
      .hamburger-icon,
      .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
      }
    }
  }