:root {
  --mainColor: rgb(172, 27, 27);
  --hoverColor: rgb(202, 76, 76);
  --backgroundColor: #1e1e1e;
  --darkOne: #f3f3f3;
  --darkTwo: #fff;
  --lightOne: #ccc;
  --lightTwo: #e7e3e3;
  --mainHex: #ac1b1b;
  --primaryContent: #f7cfcf;
  --primaryDark: #6a1f1f;
  --darkGrey: #4d4d4e;
  --complementaryColor: #FFD700;
}

.page {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  }
  
  .top-tags {
    bottom: auto;
    top: 35px;
  }
  
  .tags {
    color: var(--complementaryColor);
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
  }
  
  .bottom-tag-html {
    margin-left: -20px;
  }
  
  .container {
    width: 100%;
    will-change: contents;
    height: 100%;
    position: relative;
    opacity: 0;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  .about-page,
  .projects-page,
  .contact-page {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;

      h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: var(--complementaryColor);
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: -10px;

        @media (max-width: 768px) {
          font-size: 40px;
          font-family: 'Coolvetica';
          color: var(--complementaryColor);
          font-weight: 400;
          margin-top: 0;
          position: relative;
          margin-bottom: 10px;
          left: 0;
        }
  
        &:before {
          content: '<h1>';
          font-family: 'La Belle Aurore', cursive;
          color: var(--complementaryColor);
          font-size: 18px;
          position: absolute;
          margin-top: -10px;
          left: -10px;
          opacity: 0.6;
          line-height: 18px;
        }
  
        &:after {
          content: '<h1/>';
          font-family: 'La Belle Aurore', cursive;
          color: var(--complementaryColor);
          font-size: 18px;
          line-height: 18px;
          position: absolute;
          left: -30px;
          bottom: -20px;
          margin-left: 20px;
          opacity: 0.6;
        }
      }
  
      p {
        font-size: 13px;
        color: var(--lightOne);
        font-family: sans-serif;
        font-weight: 300;
        max-width: fit-content;
        animation: pulse 1s;
        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
          animation-delay: 1.2s;
        }
        &:nth-of-type(3) {
          animation-delay: 1.3s;
        }
      }
    }

    .text-animate-hover {
      &:hover {
        color: var(--lightOne);
        cursor: crosshair;
      }
    }
  }