@media screen and (max-width: 1200px) {
  
  .tags,
  .about-page h1:before,
  .about-page h1:after {
    display: none;
  }
  
  .about-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }

    h1 {
      font-size: 40px;
    }
  }
}