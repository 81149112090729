:root {
  --mainColor: rgb(172, 27, 27);
  --hoverColor: rgb(202, 76, 76);
  --backgroundColor: #1e1e1e;
  --darkOne: #f3f3f3;
  --darkTwo: #fff;
  --lightOne: #ccc;
  --lightTwo: #e7e3e3;
  --mainHex: #ac1b1b;
  --primaryContent: #f7cfcf;
  --primaryDark: #500a0a;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background-color: var(--primaryDark);
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1200px) {
  body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
